const MobileHeader = () => {
  return (
    <div className="mobileNav">
      <p className="mobileNavitem">ahhh</p>
      <p className="mobileNavitem">ahhh</p>
      <p className="mobileNavitem">ahhh</p>
      <p className="mobileNavitem">ahhh</p>
    </div>
  )
}

export default MobileHeader